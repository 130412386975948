<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-grid">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="accessTokenDays"
                :rules="rules"
                label="Access Token Expires day(s)"
                required
                type="number"
                class="inputNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="refreshTokenDays"
                :rules="rules"
                label="Refresh Token Expires day(s)"
                required
                type="number"
                class="inputNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="info"
            class="mr-4"
            @click="updateTokens"
            :loading="loadingUpdateTokens"
          >
            SAVE
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { parseInt } from 'lodash'
import { mapGetters } from 'vuex'
import { apiService } from '../../../services/api.service'
export default {
  computed: {
    ...mapGetters(['tokens', 'loadingUpdateTokens'])
  },
  created () {
    this.getTokens()
  },
  data: () => ({
    valid: true,
    accessTokenDays: null,
    refreshTokenDays: null,
    rules: [(v) => !!v || 'Name is required'],
    select: null,
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    checkbox: false
  }),

  methods: {
    async getTokens () {
      await apiService
        .get('/admin-get-tokens')
        .then((response) => {
          this.accessTokenDays = response.data.tokens.access_token_expires
          this.refreshTokenDays = response.data.tokens.refresh_token_expires
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateTokens () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('updateTokens', {
          accessTokenDays: parseInt(this.accessTokenDays),
          refreshTokenDays: parseInt(this.refreshTokenDays)
        })
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style>
.inputNumber input[type='number'] {
  -moz-appearance: textfield;
}

.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
